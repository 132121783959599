import React from "react";
import MaterialTable from 'material-table';
import axios from 'axios';
import moment from 'moment';

const MonitorTable = () => {
    let [monitorData, setMonitorData] = React.useState([]);

    const getMonitorData = () => {
        axios.get('http://api.shw.transcode.co.th/exchange/sensor/smc/monitor')
            .then(res => {
                for(let i = 0; i < res.data.length; i++) {
                    res.data[i]['c_sensor_datetime'] = res.data[i]['c_sensor_datetime']? moment(res.data[i]['c_sensor_datetime']).format("YYYY/MM/DD HH:mm") : "";
                    res.data[i]['sensor_datetime'] = res.data[i]['sensor_datetime']? moment(res.data[i]['sensor_datetime']).format("YYYY/MM/DD HH:mm") : "";
                    res.data[i]['status'] = `${res.data[i]['is_normal']}` === "1"? 1 : 0;
                    res.data[i]['status_desc'] = `${res.data[i]['is_normal']}` === "1"? "Normal" : "Abnormal";
                }
                setMonitorData(res.data);
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    React.useEffect(() => {
        getMonitorData()
        setInterval(getMonitorData, 300000)
    }, [])

    const cellStyle = (e, rowData) => {
        if (!rowData.status) {
            return { background: "red" };
        }
    }

    return (
        <div >
            <MaterialTable
                title="SmartMicro Monitor"
                columns={[
                    { title: 'Device', field: 'c_device_code', cellStyle: cellStyle },
                    { title: 'Hour', field: 'hr', cellStyle: cellStyle },
                    { title: 'Min Count Date', field: 'c_sensor_datetime', cellStyle: cellStyle },
                    { title: 'Latest Count Date', field: 'sensor_datetime', cellStyle: cellStyle },
                    { title: 'Min Count', field: 'c_min_count', cellStyle: cellStyle },
                    { title: 'Latest Count', field: 'v', cellStyle: cellStyle },
                    {
                        title: 'Status',
                        field: 'status_desc',
                        cellStyle: cellStyle
                    },
                ]}
                data={monitorData}
                options={{
                    exportButton: true,
                    paging: false
                }}
            />
        </div>
    )
}

export default MonitorTable
