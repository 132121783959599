import React from "react";
import MonitorTable from "../components/MonitorTable";

const Monitor = () => {
    return (
        <div>
            <MonitorTable></MonitorTable>
        </div>
    )
}

export default Monitor;
