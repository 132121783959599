import logo from './logo.svg';
import './App.css';
import Monitor from "./views/Monitor";

function App() {
  return (
    <div className="App">
      <Monitor></Monitor>
    </div>
  );
}

export default App;
